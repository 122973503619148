import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import image1 from '../assets/1.png';
import image2 from '../assets/2.png';
import image3 from '../assets/3.png';
import image4 from '../assets/4.png';
import image5 from '../assets/5.png';
import image6 from '../assets/6.png';
import image7 from '../assets/7.png';
import image8 from '../assets/8.png';
import image9 from '../assets/9.png';
import image10 from '../assets/10.png';
import image11 from '../assets/11.png';
import image12 from '../assets/12.png';



const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '8px',
  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
  height: 0,
  paddingTop: '75%', // 4:3 Aspect Ratio
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 50%)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover::after': {
    opacity: 1,
  },
}));

const StyledImageInner = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export default function ImageGallery() {
  const images = [
    { src: image1, alt: 'Image 1' },
    { src: image12, alt: 'Image 12' },
    { src: image3, alt: 'Image 3' },
    { src: image4, alt: 'Image 4' },
    { src: image11, alt: 'Image 11' },
    { src: image6, alt: 'Image 6' },
    { src: image7, alt: 'Image 7' },
    { src: image8, alt: 'Image 8' },
    { src: image9, alt: 'Image 9' },
    { src: image10, alt: 'Image 10' },
    { src: image5, alt: 'Image 5' },
    { src: image2, alt: 'Image 2' },
    // Add more images as needed
  ];
  

  return (
    <Box
      sx={{
        backgroundColor: '#1a1a1a',
        color: 'white',
        py: { xs: 8, md: 12 }, // Increased vertical padding
        px: { xs: 2, sm: 4, md: 6 }, // Added horizontal padding
      }}
    >
      <Container maxWidth="xl">
        <Typography 
          variant="h2" 
          component="h2" 
          align="center" 
          gutterBottom 
          sx={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' },
            mb: 2,
          }}
        >
          Image Gallery
        </Typography>
        <Typography 
          variant="h5" 
          component="h3" 
          align="center" 
          sx={{
            color: 'goldenrod',
            mb: { xs: 6, md: 8 }, // Increased bottom margin
            fontSize: { xs: '1.2rem', md: '1.5rem' },
          }}
        >
          Sneak peek into our barbershop
        </Typography>

        <Grid container spacing={4}> {/* Increased grid spacing */}
          {images.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ImageWrapper>
                <StyledImageInner>
                  <StyledImage src={image.src} alt={image.alt} loading="lazy" />
                </StyledImageInner>
              </ImageWrapper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
