import React from 'react';
import { Box, Container, Grid, Typography, Button, Link, IconButton, useMediaQuery, useTheme } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Box
        id ="contact"
      sx={{
        backgroundColor: 'black',
        color: 'white',
        py: { xs: 6, md: 10 },
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={{ xs: 4, md: 8 }} justifyContent="space-between">
          <Grid item xs={12} md={6} lg={7}>
            <Box sx={{ maxWidth: { md: '80%', lg: '70%' } }}>
              <Typography variant="h4" component="h2" gutterBottom sx={{ color: 'goldenrod', fontWeight: 'bold' }}>
                Get a Fresh New Look
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                Experience Our Expert Barber Services Today
              </Typography>
              <Button
                variant="contained"
                startIcon={<CalendarTodayIcon />}
                href="https://booksy.com/en-gb/102957_revolution-barbershop_barber_1576586_edinburgh#ba_s=seo"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  mt: 2,
                  backgroundColor: 'goldenrod',
                  color: 'black',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: '#DAA520',
                  },
                }}
              >
                Book Now
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Box sx={{ textAlign: { xs: 'left', md: 'right' } }}>
              <Typography variant="h6" gutterBottom sx={{ color: 'goldenrod', fontWeight: 'bold' }}>
                Contact Us
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
              55 Bread St, Edinburgh EH3 9AH, UK
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Phone: +44 7300 792350
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Email: info@revolutionbarbershop.com
              </Typography>
              <Box sx={{ mt: 2, mr: '-2rem', display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' } }}>
                <IconButton
                  href="https://www.instagram.com/revolution.barber/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'white', '&:hover': { color: 'goldenrod' }, mr: 2 }}
                >
                  <InstagramIcon fontSize="large" />
                </IconButton>
               
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box 
          sx={{ 
            mt: { xs: 4, md: 6 }, 
            pt: 3, 
            borderTop: '1px solid rgba(255, 255, 255, 0.1)', 
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'center', sm: 'flex-start' },
          }}
        >
          <Typography variant="body2" color="white" sx={{ mb: { xs: 2, sm: 0 } }}>
            © {new Date().getFullYear()} Revolution Barbershop. All rights reserved.
          </Typography>
       
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
