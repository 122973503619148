import React from 'react';
import { Box, Typography, Grid, Container, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import mark from '../assets/mark.png';

const Team = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box sx={{ 
      backgroundColor: 'black', 
      py: { xs: 8, sm: 10, md: 12, lg: 16 },
      px: { xs: 2, sm: 3, md: 4, lg: 6 },
    }}>
      <Container maxWidth="lg">
        <Typography 
          variant={isMobile ? "h4" : isLargeScreen ? "h2" : "h3"} 
          component="h2" 
          align="center" 
          gutterBottom 
          sx={{ 
            color: 'white', 
            mb: { xs: 6, sm: 8, md: 10, lg: 12 },
            fontWeight: 'bold',
            fontSize: isLargeScreen ? { lg: '3.5rem', xl: '4rem' } : undefined,
          }}
        >
          Meet Mark!
        </Typography>
        <Grid container spacing={{ xs: 6, sm: 8, md: 10 }} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                paddingTop: '100%',
                overflow: 'hidden',
                borderRadius: 2,
                boxShadow: '0 8px 24px rgba(255,255,255,0.1)',
                mb: { xs: 4, md: 0 },
              }}
            >
              <Box
                component="img"
                src={mark}
                alt="Mark - Master Barber"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ pl: { md: 4, lg: 6 } }}>
              <Typography variant="h4" component="h3" gutterBottom sx={{ color: 'goldenrod', mb: 3 }}>
                Master Barber
              </Typography>
              <Typography variant="body1" sx={{ color: 'white', mb: 4, fontSize: { xs: '1rem', md: '1.1rem', lg: '1.2rem' } }}>
                Mark is a highly skilled barber with many years of experience under his belt. His passion for the craft and attention to detail have made him one of the most sought-after barbers in Edinburgh.
              </Typography>
              <Typography variant="body1" sx={{ color: 'white', fontSize: { xs: '1rem', md: '1.1rem', lg: '1.2rem' } }}>
                With a keen eye for style and a deep understanding of different hair types, Mark can create the perfect look for any client. Whether you're looking for a classic cut or a modern style, Mark's expertise ensures you'll leave the chair looking and feeling your best.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: { xs: 6, sm: 8, md: 10, lg: 12 }, textAlign: 'center' }}>
          <Typography 
            variant={isMobile ? "h5" : isLargeScreen ? "h3" : "h4"} 
            gutterBottom 
            sx={{ 
              color: 'white', 
              fontWeight: 'bold',
              mb: isLargeScreen ? 3 : 2,
            }}
          >
            We're hiring!
          </Typography>
          <Typography 
            variant={isMobile ? "body1" : isLargeScreen ? "h5" : "h6"} 
            sx={{ 
              color: 'white', 
              mb: { xs: 3, sm: 4, md: 5 },
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            Become part of the revolution team and help shape the future of barbering.
          </Typography>
          <Button
            variant="outlined"
            href="#contact"
            size={isMobile ? "medium" : "large"}
            sx={{
              color: 'white',
              borderColor: 'white',
              padding: isMobile ? '8px 16px' : isLargeScreen ? '12px 36px' : '10px 30px',
              fontSize: isMobile ? '0.9rem' : isLargeScreen ? '1.2rem' : '1.1rem',
              '&:hover': {
                borderColor: 'goldenrod',
                color: 'goldenrod',
                backgroundColor: 'rgba(218, 165, 32, 0.1)',
              },
            }}
          >
            Contact us
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Team;
