import React from 'react';
import { Box, Typography, Grid, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';

const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        backgroundColor: 'black',
        color: 'white',
        py: { xs: 8, md: 12, lg: 16 },
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/barbershop-bg.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant={isMobile ? "h3" : isLargeScreen ? "h2" : "h3"}
                component="h2"
                sx={{ 
                  fontWeight: 'bold',
                  marginBottom: { xs: 3, md: 4 },
                  color: 'goldenrod',
                }}
              >
                Experience Exceptional Barbering
              </Typography>
              <Typography
                variant="body1"
                sx={{ 
                  marginBottom: 3,
                  fontSize: { xs: '1rem', md: '1.1rem', lg: '1.2rem' },
                }}
              >
                Revolution Barbershop, nestled in the heart of Edinburgh, is a premier destination for unparalleled barber treatments. With a name that exudes innovation and style, this exceptional establishment offers a contemporary twist on traditional grooming experiences.
              </Typography>
              <Typography
                variant="body1"
                sx={{ 
                  marginBottom: 3,
                  fontSize: { xs: '1rem', md: '1.1rem', lg: '1.2rem' },
                }}
              >
                From precision haircuts and razor-sharp fades to detailed beard trims and hot towel shaves, we specialize in creating tailored looks that reflect each client's unique personality.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  paddingTop: '75%', // 4:3 aspect ratio
                  overflow: 'hidden',
                  borderRadius: 2,
                  boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: '0 15px 40px rgba(0,0,0,0.3)',
                  },
                }}
              >
                <Box
                  component="img"
                  src="/detail.png"
                  alt="Revolution Barbershop Interior"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                />
              </Box>
            </motion.div>
          </Grid>
        </Grid>
        
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{ 
                  fontWeight: 'bold',
                  marginBottom: 2,
                  color: 'goldenrod',
                }}
              >
                Our Commitment
              </Typography>
              <Typography
                variant="body1"
                sx={{ 
                  marginBottom: 3,
                  fontSize: { xs: '1rem', md: '1.1rem', lg: '1.2rem' },
                }}
              >
                The shop's sleek and modern interior sets the stage for a sophisticated atmosphere, ensuring a comfortable and relaxed environment for every visit. Committed to using high-quality products and staying up-to-date with the latest techniques, Revolution Barbershop is dedicated to providing an elevated grooming experience that leaves clients looking and feeling their absolute best.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{ 
                  fontWeight: 'bold',
                  marginBottom: 2,
                  color: 'goldenrod',
                }}
              >
                Location & Transport
              </Typography>
              <Typography
                variant="body1"
                sx={{ 
                  marginBottom: 2,
                  fontSize: { xs: '1rem', md: '1.1rem', lg: '1.2rem' },
                }}
              >
                Our venue is conveniently located on Bread Street, in the heart of Edinburgh. We're easily accessible with several local bus routes nearby.
              </Typography>
              <Typography
                variant="body2"
                sx={{ 
                  fontSize: { xs: '0.9rem', md: '1rem', lg: '1.1rem' },
                  color: 'goldenrod',
                }}
              >
                Nearest public transport: Local bus routes on Bread Street
              </Typography>
            </Grid>
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default About;
