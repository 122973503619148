import React from 'react';
import { Box, Typography, Container, Card, CardContent, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import StarIcon from '@mui/icons-material/Star';

const testimonials = [
  {
    name: "Astrid",
    text: "Absolutely awesome place, barber is very experienced and very good at what he does. Environment is welcoming and relaxed, while still being a professional and well-respected place. I won't be going anywhere else while I'm still based in Edinburgh.",
    rating: 5,
  },
  {
    name: "Nick",
    text: "This is my first time to cut my hair in Europe. After cutting my hair, I believe that white people can also cut Asian hair well. This is a great barber shop, comfortable and special, I recommend it.",
    rating: 5,
  },
  {
    name: "David",
    text: "Mark is a true legend, gent and artist at his craft! So down to earth and so friendly! Please do go in and use him if in the area and his dog is awesome too. Awesome set up and true precision and attention to detail!! Thank you mark!",
    rating: 5,
  },
  {
    name: "Mertan",
    text: "The best barber in Edinburgh. Don't hesitate to come 👌",
    rating: 5,
  },
  {
    name: "Van",
    text: "Best Barber. This man is unique. You have to check him you. He loves his work.",
    rating: 5,
  },
  {
    name: "Peter",
    text: "Great Barber with quick hands and the magic eye of the Tiger 🐯 for the exactly right cut - all comes with California vibes - money good man‼️💯🔝",
    rating: 5,
  },
  {
    name: "Ren",
    text: "Brilliant hair cut, brilliant place, the best vibes. I've never been to a barber before and I felt completely safe and comfortable. Would recommend to literally anyone who asks. Mark is brilliant, can't wait for my hair to grow out.",
    rating: 5,
  }
  // Add more testimonials as needed
];

const TestimonialCard = ({ name, text, rating }) => (
  <Card sx={{ 
    height: '100%', 
    display: 'flex', 
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    color: 'white',
    borderRadius: 2,
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  }}>
    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', p: 4 }}>
      <FormatQuoteIcon sx={{ fontSize: 60, color: 'goldenrod', mb: 3 }} />
      <Typography variant="body1" paragraph sx={{ mb: 3, fontStyle: 'italic', fontSize: '1.1rem' }}>
        "{text}"
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        {[...Array(rating)].map((_, index) => (
          <StarIcon key={index} sx={{ color: 'goldenrod' }} />
        ))}
      </Box>
      <Typography variant="subtitle1" sx={{ mt: 'auto', color: 'goldenrod', fontWeight: 'bold', fontSize: '1.2rem' }}>
        - {name}
      </Typography>
    </CardContent>
  </Card>
);

export default function Testimonials() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: 'black',
        color: 'white',
        py: { xs: 6, md: 10 },
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/barbershop-bg.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
      }}
    >
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          component="h2" 
          align="center" 
          gutterBottom 
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2.5rem', md: '3.5rem' },
            mb: 4,
            color: 'white',
          }}
        >
          Testimonials
        </Typography>
        <Typography 
          variant="h5" 
          component="h3" 
          align="center" 
          sx={{
            mb: { xs: 4, md: 6 },
            color: 'goldenrod',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
          }}
        >
          What our clients say
        </Typography>

        {isMobile ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {testimonials.map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </Box>
        ) : (
          <Carousel
            animation="slide"
            navButtonsAlwaysVisible
            navButtonsProps={{
              style: {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: 0,
              }
            }}
            indicatorContainerProps={{
              style: {
                marginTop: '20px',
              }
            }}
            indicatorIconButtonProps={{
              style: {
                color: 'rgba(255, 255, 255, 0.4)',
              }
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: 'goldenrod',
              }
            }}
          >
            {testimonials.map((testimonial, index) => (
              <Box key={index} sx={{ p: 2 }}>
                <TestimonialCard {...testimonial} />
              </Box>
            ))}
          </Carousel>
        )}
      </Container>
    </Box>
  );
}
