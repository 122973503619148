import React from 'react';
import { Box, Typography, Paper, Container, Grid, Button } from '@mui/material';
import { motion } from 'framer-motion';
import ScissorsIcon from '@mui/icons-material/ContentCut';
import TimerIcon from '@mui/icons-material/Timer';
import MoneyIcon from '@mui/icons-material/AttachMoney';

const services = [
  { id: 1, title: 'Haircut', description: 'Bespoke haircut with years of experience. Fluent in all cuts classic and modern.', price: '£25.00', duration: '30min' },
  { id: 2, title: 'Beard trim', description: 'Precision trims. Era specific beard and moustache grooming. Tailored to suit', price: '£23.00', duration: '20min' },
  { id: 3, title: 'Hot towel shave', description: 'New York Style. The Works. 3 hot towels. Rest facial.', price: '£30.00', duration: '30min' },
  { id: 4, title: 'Just the sides', description: 'Clean and smooth beard shave.', price: '£20.00', duration: '30min' },
  { id: 5, title: 'Undercut', description: 'Stylish undercut service.', price: '£20.00', duration: '15min' },
];



const ServiceCard = ({ service }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Paper
      elevation={3}
      sx={{
        padding: '1.5rem',
        borderRadius: '0.5rem',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-10px)',
        },
      }}
    >
      <Typography variant='h5' component='h3' sx={{ 
        color: 'goldenrod', 
        fontWeight: 'bold',
        fontSize: '1.25rem',
        marginBottom: '1rem',
      }}>
        {service.title}
      </Typography>
      <Typography variant='body2' sx={{ 
        color: 'white',
        fontSize: '0.875rem',
        flexGrow: 1,
        marginBottom: '1rem',
      }}>
        {service.description}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MoneyIcon sx={{ color: 'goldenrod', marginRight: '0.5rem' }} />
          <Typography variant='h6' sx={{ 
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.1rem',
          }}>
            {service.price}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TimerIcon sx={{ color: 'goldenrod', marginRight: '0.5rem' }} />
          <Typography variant='body2' sx={{ 
            color: 'white',
            fontSize: '0.8rem',
          }}>
            {service.duration}
          </Typography>
        </Box>
      </Box>
    </Paper>
  </motion.div>
);

export default function Services() {
  return (
    <Box
      id="services"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '80vh',
        padding: { xs: '5% 3%', md: '5% 7%' },
        backgroundColor: '#1a1a1a',
        color: 'white',
      }}
    >
      <Container maxWidth="xl">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant='h2' component='h2' sx={{ 
            color: 'white',
            fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4.5rem' },
            marginBottom: '1rem',
            fontWeight: 'bold',
            textAlign: 'center',
          }}>
            Our Services
          </Typography>
          <Typography variant='h5' component='h3' sx={{ 
            color: 'goldenrod', 
            marginBottom: '3rem',
            textAlign: 'center',
          }}>
            Explore our range of professional barbering services
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {services.map((service) => (
            <Grid item xs={12} sm={6} md={4} key={service.id}>
              <ServiceCard service={service} />
            </Grid>
          ))}
        </Grid>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          mt: '4rem', 
        }}>
          <Button 
            variant="contained"
            startIcon={<ScissorsIcon />}
            href="https://booksy.com/en-gb/102957_revolution-barbershop_barber_1576586_edinburgh#ba_s=seo"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: 'goldenrod',
              color: 'black',
              fontWeight: 'bold',
              padding: '12px 24px',
              fontSize: '1rem',
              textTransform: 'uppercase',
              borderRadius: '50px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: '#DAA520',
                boxShadow: '0 6px 8px rgba(0,0,0,0.3)',
                transform: 'translateY(-2px)',
              },
              '&:active': {
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                transform: 'translateY(0)',
              },
            }}
          >
            Book Now
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
