import React, { useState, useEffect } from 'react';
import isMobile from './utils/isMobile';
import MobileWarning from './components/MobileWarning';
import Home from './pages/Home';

function App() {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (isMobile()) {
      setShowWarning(true);
    }
  }, []);

  const handleContinue = () => {
    setShowWarning(false);
  };

  return (
   <Home />
  );
}

export default App;
