import React from 'react';
import { Box, Button, Typography, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from 'framer-motion';

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 
      id="home"
      sx={{ 
        position: 'relative', 
        height: '100vh', 
        overflow: 'hidden',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.8) 100%)',
          zIndex: 1,
        }
      }}
    >
      <Box 
        component="img"
        src="/hero-image.png"
        alt="Revolution Barbershop"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      />
      <Container
        maxWidth="lg"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography 
            variant="h1" 
            sx={{
              color: 'white',
              fontWeight: 500,
              fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem', lg: '4.5rem' },
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              mb: 2,
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            Welcome to The Revolution Barbershop Experience
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Typography 
            variant="h2" 
            sx={{
              color: 'goldenrod',
              fontWeight: 500,
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
              mb: 4,
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            Experience the Art of Barbering
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              justifyContent: isMobile ? 'center' : 'flex-start',
            }}
          >
            <Button
              variant="contained"
              startIcon={<CalendarTodayIcon />}
              href="https://booksy.com/en-gb/102957_revolution-barbershop_barber_1576586_edinburgh#ba_s=seo"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                backgroundColor: 'goldenrod',
                color: 'black',
                fontWeight: 'bold',
                padding: '12px 24px',
                fontSize: { xs: '0.9rem', sm: '1rem' },
                textTransform: 'uppercase',
                borderRadius: '50px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: '#DAA520',
                  boxShadow: '0 6px 8px rgba(0,0,0,0.3)',
                  transform: 'translateY(-2px)',
                },
                '&:active': {
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  transform: 'translateY(0)',
                },
              }}
            >
              Book Now
            </Button>
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
              href="#services"
              sx={{
                color: 'white',
                borderColor: 'white',
                borderWidth: 2,
                fontWeight: 'bold',
                padding: '12px 24px',
                fontSize: { xs: '0.9rem', sm: '1rem' },
                textTransform: 'uppercase',
                borderRadius: '50px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  borderColor: 'goldenrod',
                  color: 'goldenrod',
                },
              }}
            >
              Our Services
            </Button>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Hero;