import React from 'react';
import { Box } from '@mui/material';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Team from '../components/Team';
import ImageGallery from '../components/ImageGallery';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';

function Home() {
  return (
    <Box>
      <NavBar />
      <Box id="home">
        <Hero />
      </Box>
      <Box id="about">
        <About />
      </Box>
      <Box id="services">
        <Services />
      </Box>
      <Box id="team">
        <Team />
      </Box>
      <Box id="gallery">
        <ImageGallery />
      </Box>
      <Box id="testimonials">
        <Testimonials />
      </Box>
      <Footer />
    </Box>
  );
}

export default Home;